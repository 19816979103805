@import "~bulma/sass/utilities/mixins";

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin clearfix {
  &::after {
    clear: both;
    content: " ";
    display: table;
  }
}

@mixin fp(
  $property,
  $min,
  $max,
  $start: 320,
  $end: 1440,
  $clip: true,
  $clipAtStart: true,
  $clipAtEnd: true
) {
  $multiplier: ($max - $min) / ($end - $start) * 100;
  $adder: ($min * $end - $max * $start) / ($end - $start);
  $formula: calc(#{$multiplier + 0vw} + #{$adder + 0px});
  @if $clip and $clipAtStart {
    @media (max-width: #{$start + 0px}) {
      #{$property}: $min + 0px;
    }
  }
  @if $clip and $clipAtEnd {
    @media (min-width: #{$end + 0px}) {
      #{$property}: $max + 0px;
    }
  }
  #{$property}: $formula;
}

@mixin retina {
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}

// Responsiveness

@mixin mobile {
  @media screen and (max-width: #{$upToPhone}) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: #{$atLeastTablet}) {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (min-width: #{$atLeastTablet}) and (max-width: #{$upToTablet}) {
    @content;
  }
}

@mixin touch {
  @media screen and (max-width: $atLeastDesktop - 1px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$atLeastDesktop}) {
    @content;
  }
}

// Placeholders

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

%unselectable {
  @include unselectable;
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}
