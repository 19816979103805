$textGrey: #808080;
$darkGrey: #333333;
$header: 160px;
$headerMobile: 100px;

$unit: 20px;

$mobile: 767px;
$gridWidth: 1140px;
$bodyWidth: 1440px;

$sans: 'Montserrat', "Avenir", "Avenir Next", "Helvetica Neue", sans-serif;
$serif: 'Montserrat', serif;

@import "scss/vars";
@import "scss/mixins";
@import "scss/hamburger";

@import "~glightbox/dist/css/glightbox.css";

// http://hugeinc.github.io/flexboxgrid-sass/

$flexboxgrid-breakpoints: sm 48rem,
  // ipad portrait
    md 64rem,
  // ipad landscape
    lg 80rem,
  // 1280
    xlg 90rem; // 1440

$grid-columns: 12;
$gutter-width: 50px;
$outer-margin: 25px;

@import "~flexboxgrid-sass/flexboxgrid";

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: $sans;
  * {
    box-sizing: inherit;
  }
}

body {
  max-width: $bodyWidth;
  margin: 0 auto;
  @include tablet {
    margin-top: 110px;
  }
}

h1,
h2,
h4 {
  font-family: $serif;
  font-weight: 400;
  b {
    font-weight: 700;
  }
}

h1{
  @include fp(font-size, 20, 38);
  color: #404F54;
  letter-spacing: 0;
  text-align: center;
}

h2 {
  @include fp(font-size, 20, 28);
}

h3 {
  color: $textGrey;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0;
  text-align: center;
  line-height: 26px;
}

h4 {
  @include fp(font-size, 16, 24);
  line-height: 1.3;
  margin-top: 4rem;

  font-weight: 700;
  font-size: 22px;
  color: #404F54;
  letter-spacing: 0;
  &.brands {
    color: rgba(#000, 0.8);
    letter-spacing: 2px;
    font-size: 36px;
  }
}

p {
  @include fp(font-size, 14, 16);
  line-height: 1.5;
  margin-bottom: 2em;
}

a {
  color: $textGrey;
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin-bottom: 3em;
  li {
    margin-bottom: 1em;
  }
}

img {
  width: 100%;
  max-width: 100%;
}

.wrapper{
  width: 100%;
  max-width: 1280px;
  @include mobile{
    padding: 0 20px;
  }
}

.spacer {
  height: 5rem;
  @include mobile{
    height: 2rem;
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.hidden-tablet,
.hidden-desktop {
  @include tablet {
    display: none !important;
  }
}

.hidden-mobile {
  @include mobile {
    display: none !important;
  }
}

.mobilePadding {
  @include mobile {
    padding-left: 50px;
    padding-right: 50px;
  }
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  letter-spacing: 2.5px;
  @include tablet {
    .mobile {
      display: none;
    }
    p {
      margin: 0;
    }
    .headerWrapper {
      width: 100%;
      max-width: $bodyWidth;
      margin: 0 auto;
      background: #fff;
      box-shadow: 0 2px 25px rgba(#000, 0.2);
      padding: 20px 40px;
    }
    .logo {
      max-width: 236px;
    }
    .openings {
      color: $textGrey;
    }
    .openingTimes {
      margin-left: 20px;
    }
    ul{
      display: flex;
      padding: 0;
      margin: 0;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      li{
        display: flex;
        margin: 0 0 0 20px;
      }
    }
    .socialMedia {
      display: flex;
      align-items: center;
      &:first-child {
        margin-bottom: 10px;
      }
      a {
        display: flex;
        align-items: center;
      }
      img {
        width: 24px;
        max-width: 24px;
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
  @include mobile {
    .desktop {
      display: none;
    }

    .mobile {
      background: #fff;
      display: flex;
      justify-content: space-between;
      padding: 20px;
      .callLink {
        display: flex;
        align-items: center;
        img {
          width: 24px;
          max-width: 24px;
          margin-right: 10px;
        }
      }
    }
  }
}

.instagramImages {
  display: flex;
  flex-wrap: wrap;
  @include mobile {
    .col-xs-6 {
      padding: 0;
    }
  }
  .instagramImage {
    height: 160px;
    position: relative;
    overflow: hidden;
    display: flex;

    @include mobile {
      height: calc(50vw);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .hover {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(#000, 0.8);
      color: #fff;
      padding: 20px;
      transform: translateY(100%);
      transition: all 0.3s ease-in-out;
      font-size: 12px;
      @include mobile {
        display: none;
      }
    }
    @include tablet {
      margin-bottom: $gutter-width;
      &:hover {
        .hover {
          transform: translateY(0%);
        }
      }
    }
  }
}

.footer{
  background: #404f54;
  padding: 80px 0;
  .logo{
    max-width: 118px;
    opacity: .5;
  }
  ul{
    display: flex;
    padding: 0;
    margin: 30px 0 0;
    width: 100%;
    align-items: center;
    li{
      display: flex;
      margin: 0 20px 0 0;
      a{
        color: #fff;
        opacity: .5;
        &:hover{
          opacity: 1;
        }
      }
    }
  }
}

@import "scss/cms";
