.cms {
  * {
    font-family: "Avenir Pro", "Avenir", "Avenir Next", "Helvetica Neue",
      sans-serif;
  }

  background: #f8f8f8;
  padding: 40px;

  section {
    border: 1px solid rgba(#000, 0.2);
    padding: 20px;
    margin-bottom: 40px;
    margin-top: 40px;
    background: #fff;
    h1 {
      color: grey;
    }
  }

  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    label {
      width: 100%;
    }
    textarea,
    input {
      width: 100%;
      max-width: 100%;
      font-size: 18px;
    }
  }

  .login {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    background: rgba(#000, 0.9);
    input {
      width: 100%;
      padding: 8px;
      font-size: 18px;
      margin-bottom: 10px;
      &[type="submit"] {
        font-size: 18px;
        appearance: none;
      }
    }
  }
}

.dropzone {
  p {
    margin: 0;
  }
}
