#hamburgerMenu {
  @media (min-width: $phone) {
    display: none !important;
  }
}

.burger-menu-wrapper {
  //position: fixed;
  //position: absolute;
  //right: 20px;
  //top: 100%;
  //right: 20px;
  display: inline-flex;
  align-items: center;
  z-index: 9999;
  color: #000;
  @media (min-width: $phone) {
    display: none !important;
  }
  .popup-overlay {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    > div {
      margin: 0;
    }
    ul {
      margin-bottom: 50px;
    }
    .iconwrapper {
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
      margin-top: 10px;
    }
  }
}

.burger-menu,
.burger-menu.open {
  display: inline-block;
  cursor: pointer;
  position: relative;
  z-index: 9999;
  //background: $primary-200;
  //padding: 10px;
  border-radius: 25px;
  margin-left: 10px;
  //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.burger-menu .bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 3px;
  background-color: #000;
  margin: 4px 0;
  transition: 0.4s;
}

.burger-menu.open .bar1 {
  -webkit-transform: rotate(-45deg) translate(-4px, 4px);
  transform: rotate(-45deg) translate(-4px, 4px);
}

.burger-menu.open .bar2 {
  opacity: 0;
}

.burger-menu.open .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -6px);
  transform: rotate(45deg) translate(-6px, -6px);
}

.hamburgermenu {
  text-align: center;
  font-size: 16px;
  line-height: 1.8;
  p {
    font-size: 20px;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  a {
    color: #000 !important;
  }
  li {
    margin-bottom: 15px;
  }
  img {
    margin-bottom: 20px;
    width: 190px;
  }
}
