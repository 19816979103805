@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/controls";

$phone: 760px;
$tablet: 1200px;

$upToPhone: $phone;

$upToTablet: $tablet;
$atLeastTablet: $phone + 1;

$atLeastDesktop: $tablet + 1;

$white: hsl(0, 0%, 100%);
$black: hsl(0, 0%, 4%);

$primary: #00677f;
$primary-100: #dfedf2;
$primary-200: #a6cad8;
$primary-300: #79aebf;
$primary-400: #5097ab;
$primary-500: $primary;
$primary-600: #00566a;
$primary-700: #014355;
$primary-800: #003340;
$primary-900: #001f26;

$secondary: #72170c;
$secondary-100: #e6d2d2;
$secondary-200: #c8a0a0;
$secondary-300: #aa736e;
$secondary-400: #8c463c;
$secondary-500: $secondary;
$secondary-600: #5a130a;
$secondary-700: #440f06;
$secondary-800: #2b0a04;
$secondary-900: #120402;

$grey: #707070;
$grey-100: #fafafa;
$grey-200: #e6e6e6;
$grey-300: #c8c8c8;
$grey-400: #9e9e9e;
$grey-500: $grey;
$grey-600: #444444;
$grey-700: #2b2b2b;
$grey-800: #1c1c1c;
$grey-900: #0a0a0a;

$daimler-grey: #a6acb5;

$success: #7ed321;
$success-100: #e1ebdd;
$success-200: #c8dcb4;
$success-300: #aac891;
$success-400: #8cb46e;
$success-500: $success;

$warning: #e69123;
$warning-100: #fae6d2;
$warning-200: #f5d2aa;
$warning-300: #f0be7c;
$warning-400: #eba550;
$warning-500: $warning;

$colors: (
  "primary": (
    $primary,
    $white
  ),
  "primary-300": (
    $primary-300,
    $white
  ),
  "secondary": (
    $secondary,
    $white
  ),
  "grey": (
    $grey,
    $white
  ),
  "daimler-grey": (
    $daimler-grey,
    $white
  ),
  "success": (
    $success,
    $white
  ),
  "warning": (
    $warning,
    $white
  ),
  "white": (
    $white,
    $black
  )
);

$header-height-mobile: 2.5rem;
$header-padding-x-mobile: 0.625rem;
$header-padding-y-mobile: 0.625rem;

$header-height-desktop: 4.5rem;
$header-padding-x-desktop: 1.875rem;
$header-padding-y-desktop: 1.25rem;

$fontName: "Daimler CS";

$h1: calculateRem(40px) !default;
$h2: calculateRem(34px) !default;
$h3: calculateRem(28px) !default;
$h4: calculateRem(20px) !default;
$h5: calculateRem(15px) !default;
$p: calculateRem(15px) !default;

$gutter: 0.625rem;
$column-gap: $gutter;

$widescreen-enabled: false;

$mobileFeedbackHeaderHeight: 50px;
$mobileFeedbackFooterHeight: 60px;

$radius-small: 2px;

:root {
  --h00: 4rem;
  --h0: 3rem;
  --h1: 2rem;
  --h2: 1.5rem;
  --h3: 1.25rem;
  --h4: 1rem;
  --h5: 0.875rem;
  --h6: 0.75rem;

  --space-1: 1rem;
  --space-2: 2.5rem;
  --space-3: 5rem;
  --space-4: 10rem;

  --breakpoint-sm: (min-width: 40em);
  --breakpoint-md: (min-width: 52em);
  --breakpoint-lg: (min-width: 64em);
}
